import {TranslationMapping} from "../localization";

const mapping: TranslationMapping = {
  "signup": "Create an account",
  "login": "Login",
  "username": "Username",
  "password": "Password",
  "dashboard": "Home",
  "medications": "Medications",
  "unknown": "Unknown",
  "import": "Import",
  "messages": "Messages",
  "profile": "Profile",
  "get-care": "Get Care",
  "anonymous-refill": "Refill without an account",
  "apple-signup": "Sign up with Apple",
  "apple-continue": "Continue with Apple",
  "apple-login": "Apple login",
  "lets-get-to-know-you": "Let's get to know you",
  "assigned-to": "Assigned to",
  "assignee-required": "Assignee is required",
  "sort-by-due-date": "Sort by due date",
  "sort-by-assignee": "Sort by assignee",
  "sort-by-task-summary": "Sort by task summary",
  "ascending": "Ascending",
  "descending": "Descending",
  "arabic": "Arabic",
  "chinese": "Chinese",
  "vietnamese": "Vietnamese",
  "korean": "Korean",
  "german": "German",
  "russian": "Russian",
  "fullname": "Full name",
  "nobody": "Nobody",
  "lets-get-to-know-you-details": "Complete your new patient form so we have all your information on-file. This ensures we can fill your prescription correctly.",
  "welcome": "Welcome",
  "no-services": "No services",
  "logout": "Logout",
  "make-primary": "Make primary",
  "make-default": "Make default",
  "default-availability": "Default availability",
  "updated": "Updated {{date}}",
  "front": "Front",
  "chat": "Chat",
  "back": "Back",
  "otc-medications": "Over-The-Counter Medications",
  "overdue": "Overdue",
  "next": "Next",
  "scan-barcode": "Scan Barcode",
  "add-card": "Add a card...",
  "primary-secondary-photo-id": "Primary, secondary, photo I.D",
  "scanned": "Scanned",
  "save": "Save",
  "forgot-password": "Forgot password?",
  "coming-up": "Coming up:",
  "view": "View",
  "back-to-login": "Back to login",
  "camera-access-denied": "Unable to access camera, please go to your app settings and grant camera access.",
  "username-is-required": "Username is required",
  "password-is-required": "Password is required",
  "summary-is-required": "Summary is required",
  "recurrence": "Recurrence",
  "recurring": "Recurring",
  "high-priority": "High priority",
  "view-full-profile": "View Full Profile",
  "insurance-card": "Insurance Card",
  "barcode-scanner": "Barcode Scanner",
  "english": "English",
  "spanish": "Spanish",
  "french": "French",
  "frequency": "Frequency",
  "yes": "Yes",
  "no": "No",
  "continue": "Continue",
  "first-name": "First name *",
  "first-name-is-required": "First Name is required",
  "last-name": "Last name *",
  "last-name-is-required": "Last Name is required",
  "email-short": "Email",
  "email": "Email address",
  "every-month": "Every month",
  "months": "months",
  "last-day": "Last Day",
  "reoccurrence-must-have-context": "Reoccurrence field must have a parent form context",
  "start-date-lower-end-date": "Start date needs to be set before the end date.",
  "must-valid-domain": "Must be a valid domain",
  "email-is-required": "Email is required",
  "email-continue": "Continue with email",
  "mobile-number": "Mobile number *",
  "mobile-number-is-required": "Mobile Number is required",
  "birth-date": "Date of birth (mm/dd/yyyy) *",
  "coming-soon": "Planned for MVP, not yet available",
  "section-on-the-roadmap": "{{section}} on the roadmap, not yet available",
  "insurance-and-id-cards": "Insurance and ID cards",
  "primary": "Primary",
  "secondary": "Secondary",
  "id-card": "ID Card",
  "bottle-cap-preference": "Bottle cap preference",
  "allergies-conditions-caregivers": "Allergies, conditions, and caregivers",
  "insurance-id-card": "Insurance and I.D. Card",
  "patient-forms": "Patient Forms",
  "communication-preferences": "Communications Preferences",
  "sign-out": "Sign out",
  "high": "High",
  "low": "Low",
  "medium": "Medium",
  "submission": "Submission",
  "subtitle": "Subtitle",
  "no-submission-found": "No submissions found",
  "didnt-receive-text-message": "Didn't receive the text message?",
  "phone-number-is-sms-capable": "Please make sure that the phone number you entered is SMS-capable or resend code.",
  "standard-text-messages-apply": "Standard text messaging rates may apply\ndepending on your service provider.",
  "birth-date-is-required": "Birth Date is required",
  "lets-get-started": "Let's get started by creating an account.",
  "front-of-card": "Front of card",
  "back-of-card": "Back of card",
  "please-upload-image": "Please upload an image",
  "service-required": "Service is required",
  "add-front-of-card": "Add front of card",
  "add-back-of-card": "Add Back of card",
  "cancel": "Cancel",
  "cancellations": "Cancellations",
  "retake": "Retake",
  "signature": "Signature",
  "use-photo": "Use Photo",
  "password-must-include": "Password must include:",
  "at-least-8-characters": "At least 8 characters",
  "task-type-required": "Task type title is required",
  "task-type-title-exceeded": "The title must not exceed 30 characters",
  "task-type-description-required": "Task type description is required",
  "task-type-description-exceeded": "The description must not exceed 130 characters",
  "at-least-1-number": "At least 1 number",
  "characters": "characters",
  "at-least-1-capital-letter": "At least 1 capital letter",
  "at-least-1-lowercase-letter": "At least 1 lowercase letter",
  "at-least-1-special-character": "At least 1 special character",
  "privacy-policy-error": "Please read the privacy policy before agreeing",
  "privacy-policy-agree": "I have read and agree to the Privacy Policy",
  "privacy-policy-email-signup": "Sign up for email from my pharmacy",
  "pharmacy-privacy-policy": "Pharmacy Privacy Policy",
  "terms-and-conditions": "Software Provider Terms & Conditions",
  "terms-of-service": "Terms and Conditions",
  "terms-of-service-error": "Please read the terms of service before agreeing",
  "terms-of-service-agree": "I have read and agree to the Terms and Conditions",
  "terms-of-service-email-signup": "Sign up for email from software provider",
  "and": "and",
  "submit": "Submit",
  "update-personal-info": "Update Personal Info",
  "name-phone-email": "Name, phone, email, etc.",
  "2-of-2-agreements": "2 of 2 agreements",

  "medical-info-step-3": "Step 3 of 8 / Add your medical information",
  "password-entry-does-not-meet-criteria": "Password entry does not meet criteria",
  "password-entries-do-not-match": "Password entries do not match",
  "create-your-account": "Create Your Account",
  "please-enter-your-new-password": "Please enter your new password",
  "email-is-not-valid": "Email is not valid",
  "phone-is-not-valid": "Phone number is not valid",
  "create-account": "Create account",
  "confirm-password": "Confirm Password",
  "patient-info-step": "Step x of y / Confirm your information",
  "communications-language": "Communications Language",
  "prescriptions-communications": "Prescription Communications: How would you like to be notified when your prescriptions are ready?",
  "automated-voice-call": "Automated Voice Call",
  "text-message": "Text message",
  "app-notifications": "App Notifications",
  "all-priorities": "All priorities",
  "all-visibilities": "All visibilities",
  "all-types": "All types",
  "all-other-pharmacy-communications": "All other pharmacy communications",
  "same-time-preferences": "Would you like your prescriptions to be ready together at the same time each month?",
  "additional-information": "Additional information",
  "visually-impaired": "Visually Impaired",
  "hearing-impaired": "Hearing Impaired",
  "easy-open-bottle-caps": "Easy-open bottle caps",
  "personal-info-step-2": "Step 2 of 7 / Add your personal information",
  "country": "Country",
  "country-is-required": "Country is required",
  "state": "State",
  "state-is-required": "State is required",
  "city": "City",
  "city-is-required": "City is required",
  "street-address": "Street Address",
  "street-address-is-required": "Street Address is required",
  "street-address-line-2": "Street Address Line 2",
  "zip-code": "Zip Code",
  "zip-code-is-required": "Zip Code is required",
  "gender": "Gender (as it appears on insurance)*",
  "gender-is-required": "Gender is required",
  "male": "Male",
  "female": "Female",
  "sent": "Sent",
  "verification-code-resent": "Verification code resent",
  "please-enter-code": "Please enter the code we sent to",
  "didnt-receive-email": "Didn't receive the email? ",
  "please-check-spam-folder": "Please check your spam folder or resend code",
  "resend-code": "Resend Code",
  "restriction": "Restrictions",
  "reset-password": "Reset password",
  "reset-link-sent": "If the email is associated with an active account, you should receive a reset link.",
  "please-enter-email": "Please enter the email address\nassociated with your account",
  "how-would-you-like-to-receive-reset-link": "How would you like to receive the reset link?",
  "using-the-phone-number-on-file": "Using the phone number on file",
  "send-reset-link": "Send reset link",
  "create-new-password": "Create new password",
  "for-security-reasons": "For security reasons, your password expires every 90 days, Please update your password.",
  "update-password": "Update password",
  "password-expiring-soon": "Password expiring soon",
  "password-expire-in-7-days": "Your password will expire in 7 days.",
  "update-password-now": "Do you want to update your password now?",
  "new-password": "New password *",
  "confirm-new-password": "Confirm password *",
  "confirm-update-password": "Confirm new password *",
  "change-password": "Change password",
  "login-with-email": "Login with email",
  "login-enter-email": "Enter email",
  "go-to-login": "Go to login",
  "return-to-login": "Return to login",
  "passwords-must-match": "Passwords do not match",
  "dismiss": "Dismiss",
  "load-more": "Load More",
  "loading": "Loading",
  "open-map": "Open Map",
  "input-field": "Input field",
  "text-field": "Text field",
  "show-hide-password": "Show/Hide Password",
  "search": "Search",
  "select": "Select",
  "use": "Use",
  "no-options": "No options",
  "options": "Options",
  "add-new-option": "Add new option",
  "no-task": "No task to show",
  "disable-filters": "Please disable some filters or relax your search query.",
  "email-or-password-incorrect": "Invalid email or password entered, please try again.",
  "unable-to-complete-registration": "Unable to complete registration. Please try again.",
  "unable-to-update-account": "Unable to update account. Please try again.",
  "unable-to-complete-verification": "Unable to complete verification. Please try again.",
  "unable-to-resend-verification-code": "Unable to resend verification code. Please try again.",
  "error-submitting": "Error submitting. Please try again.",
  "new-form": 'New form',
  "trash": "Trash",
  "name-not-available": 'Name not available',
  "birthdate-not-available": 'Birth date not available',
  "unable-to-send-reset-link": "Unable to send reset link. Please try again.",
  "unable-to-reset-password": "Unable to reset password. Please try again.",
  "store-selector-change": "Change",
  "store-selector-search-address": "Zip code",
  "store-selector-change-store": "Change Store",
  "store-selector-your-store": "Your Store",
  "store-selector-nearby-store": "Nearby Stores",
  "store-selector-close": "Close",
  "store-selector-select": "Select",
  "not-implemented": "Not Yet Implemented",
  "store-selector-no-stores-error": "Unable to find pharmacy location",
  "retrieving": "Retrieving...",
  "feedback": "Feedback",
  "feedback-give-feedback": "Give Feedback",
  "feedback-give-your-feedback": "Give your feedback",
  "feedback-please-enter-feedback": "Please enter feedback",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Feedback sent!",
  "feedback-thank-you": "Thank you for your feedback!",
  "feedback-what-type-of-feedback": "What type of feedback are you leaving today?",
  "feedback-target-is-required": "Feedback target is required.",
  "feedback-feedback": "Enter additional information",
  "feedback-feedback-is-required": "Feedback info is required.",
  "feedback-allowContact": "I agree to you contacting me for further clarifications.",
  "feedback-thanks-message": "Thanks for your feedback!",
  "feedback-reach-you-message": "We will reach out to you directly if we have questions.",
  "feedback-back-to-profile": "Back to profile",
  "feedback-error-occurred": "An error has occurred.",
  "feedback-contact-info": "Contact info",
  "feedback-edit": "Edit",
  "feedback-email-address": "Email address",
  "feedback-phone-number": "Phone number",
  "feedback-app": "App",
  "feedback-location": "Store",
  "refill-card-medication": "Medication",
  "refill-card-due-date": "Refill due",
  "prescription-card-day-supply": "day supply",
  "prescription-card-filled-on": "Filled on:",
  "prescription-card-last-filled": "Last filled:",
  "prescription-card-last-ordered": "Last Ordered:",
  "prescription-card-refill-due": "Refill due:",
  "remove": "Remove",
  "remove-patient": "Remove Patient",
  "remove-item": "Remove item",
  "form-submission-not-deleting": "Forms with submissions may not be deleted",
  "prescription-card-refills": "Refills remaining",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Fill Your Prescription",
  "medications-ready-to-be-filled": "Ready to be filled",
  "medications-other-upcoming-refills": "Other upcoming refills",
  "insurance-card-upload-error": "Unable to upload card. Please try again.",
  "camera-mask-label": "Center the front of your card in the frame",
  "primary-insurance-add-your-information": "Add your primary insurance card",
  "secondary-insurance-add-your-information": "Add your secondary insurance card",
  "photoId": "Add your photo ID",
  "delete-image": "Deleting this image will remove this card information",
  "photo-take-card-picture": "Take a picture or upload an image of the front side of your government-issued photo I.D. card",
  "insurance-take-card-picture": "Take a picture or upload an image of both sides of your insurance card",
  "photo": "Photo",
  "take-photo": "Take Photo",
  "choose-photo": "Choose Photo",
  "insurance-bring-to-store": "I will bring it to the store",
  "google-signup": "Sign up with Google",
  "google-continue": "Continue with Google",
  "google-login": "Google login",
  "google-login-failed": "Google Login Failed",
  "date-is-not-valid": "Date is not valid",
  "verify-your-email": "Verify your email address",
  "verify-your-phone": "Verify your phone number",
  "please-enter-your-information": "Please enter your information",
  "email-address": "Email address",
  "patient-stores": "Find your store",
  "user-not-found": "Unknown error. Please make sure that your email address and the pharmacy you’ve selected are correct.",
  "invalid-confirmation-code": "Invalid reset link, click here to get a new one",
  "contact-support": "Unknown error, please try again. If problem persists, please contact support.",
  "many-failed-attempts": "Too many failed attempts, please try again in an hour",
  "not-authorized": "Invalid reset link, click here to get a new one",
  "done": "Done",
  "do-this-later": "Do this later",
  "entered-prescription-manually": "Entered Manually",
  "your-prescriptions": "Your prescriptions",
  "add": "Add",
  "drug-name": "Drug name",
  "drag-change-order": "Drag to change the order",
  "drug-name-is-required": "Drug name is required",
  "rx-number": "Rx number",
  "add-your-prescriptions": "Add your prescriptions",
  "retrieve-your-prescriptions": "Retrieve your prescriptions",
  "other-meds": "I have other meds not on this list",
  "try-again": "Try again",
  "step": "Step",
  "rx-number-is-required": "Rx number is required",
  "rx-number-is-duplicated": "Rx number is already present",
  "change": "Change",
  "location": "Location",
  "locations": "Locations",
  "patient-dob": "Patient DOB (mm/dd/yyyy) *",
  "patient-dob-is-required": "Date of birth is required",
  "date": "Date",
  "patient-last-name-is-required": "Patient last name is required",
  "patient-last-name": "Patient last name *",
  "we-found-your-medications-alert-title": "We found your medications",
  "unable-to-locate-your-prescription-alert-title": "Unable to locate your prescription list",
  "unable-to-locate-your-prescription-alert-description": "Please confirm that the information below matches what's on your prescription label",
  "prescription-information": "Prescription information",
  "integrated-prescription-flow-description": "Enter any recent prescription number to connect your prescription list with the specific store you selected",
  "forms": "Forms",
  "tasks": "Tasks",
  "task-types": "Task types",
  "tasks-grid": "Tasks Grid",
  "patients": "Patients",
  "grid": "Grid",
  "browse": "Browse",
  "create-first-form": "Create your first form",
  "credentials": "Credentials",
  "form-imported-text": "This form was imported and it features a functionality that is not currently available. You can still preview the form, but you cannot make edits to it.",
  "form-not-imported-text": "This form contains a functionality that is not currently supported. You can still preview the form, but you cannot make edits to it.",
  "lumistry-api-key": 'The API key you provide will give Lumistry access to your Jotform data including forms and submissions. Your data will be kept secure and will only be used for the purpose of importing the forms you specify into theLumistry platform.',
  "refill-submissions": "Refill Submissions",
  "refill-submissions-header": "Refill submissions",
  "user": "User",
  "users": "Users",
  "copy-link": "Copy link",
  "form-was-imported": "Form was imported",
  "search-submissions": "Search submissions",
  "new-user": "New user",
  "user-details": "User Details",
  "title": "Title",
  "role": "Role",
  "last-active": "Last Active",
  "registered": "Registered",
  "activity": "Activity",
  "show": "Show",
  "edit": "Edit",
  "view-activity-logs": "View activity logs",
  "resend-invite": "Resend invite",
  "delete": "Delete",
  "delete-confirmation": "Are you sure you want to delete this?",
  "confirm-delete-availability-implicit": "Are you sure you want to delete the availability?",
  "confirm-delete-service-implicit": "Are you sure you want to delete the service?",
  "cannot-undo-action": "You cannot undo this action.",
  "email-is-invalid": "Email is invalid",
  "passwords-do-not-match": "Password entries do not match",
  "password-does-not-meet-criteria": "Password entry does not meet criteria",
  "follow-reset-password-instructions": "Please follow the instructions to reset your password.",
  "pharmacy-reset-link-sent": "An email has been sent to ",
  "need-help": "Need Help?",
  "set-your-preferences": "Step {{currentStep}} of {{totalSteps}} / Set your preferences",
  "find-your-store": "Find your store",
  "confirm-your-store": "Step {{currentStep}} of {{totalSteps}} / Confirm your store",
  "prescribed-for": "Prescribed for",
  "placeholder-step": "Placeholder Step",
  "instructions": "Instructions",
  "instructions-api": "Accessing an existing JotForm account in Lumistry requires an API key found in the Jotform settings.",
  "pdf-instructions": "The .pdf below contains a short, step-by-step guide for obtaining this key.",
  "open-pdf": "Open PDF With Instructions",
  "last-filled-on": "Last filled on",
  "last-ordered-on": "Last ordered on",
  "auto-refills": "Auto-refills",
  "auto-refill": "Auto-refill",
  "refills-left": "Refills left",
  "quantity-dispensed": "Quantity dispensed",
  "days-supply": "Days supply",
  "directions": "Directions",
  "questions-about-medication": "Questions about this medication?",
  "ask-your-pharmacist": "Ask your pharmacist",
  "message": "Message",
  "refill": "Refill",
  "child": "Child",
  "spouse": "Spouse",
  "parent": "Parent",
  "grandparent": "Grandparent",
  "grandchild": "Grandchild",
  "pet": "Pet",
  "partner": "Partner",
  "other": "Other person under my care",
  "resources": "RESOURCES",
  "medication-guides": "Medication Guides",
  "how-to-take": "How to take this medication",
  "patient-education-sheets": "Patient Education Sheets",
  "side-effects-interactions": "Side effects, drug interactions",
  "prescription-written": "Rx date written",
  "prescription-by": "Prescription by",
  "call-prescriber": "Prescriber: ",
  "link-expired": "The link has expired or is invalid, you'll need a new one.",
  "reset-unsuccessful": "Reset unsuccessful",
  "order-submitted": "Your order is submitted!",
  "order": "Order",
  "picking-up-at": "Picking up at",
  "ready-today": "Ready today between",
  "notify-you": "We'll be sure to notify you",
  "hi": "Hi, {{firstName}}.",
  "create-your-password": "Please create your password",
  "i-have-read-and-agree": "I have read and agree to the",
  "pharmacy-terms-and-conditions": "Terms and Conditions",
  "privacy-policy": "Privacy Statement",
  "terms-and-conditions-required": "Terms and Conditions agreement is required",
  "privacy-policy-required": "Privacy Statement agreement is required",
  "new-patient": "New patient",
  "i-am-a-new-patient": "I am a new patient",
  "continuation-disclaimer": "By tapping Continue with Apple, Google, or email, you agree to this Pharmacy's",
  "patient-details": "Patient Details",
  "date-of-birth": "Date of birth",
  "date-of-birth-short": "DOB",
  "phone-number": "Phone number",
  "gender-text": "Gender",
  "language": "Language",
  "conditions": "Conditions",
  "easy-open-bottle": "Easy-open bottle",
  "med-sync": "Med Sync",
  "photo-id": "ID Card",
  "image": "Image",
  "notification": "Notification",
  "app-notification": "App notification",
  "list": "List",
  "providers": "Providers",
  "puc-permission-access-to-records": "I confirm that {{firstName}} {{lastName}} has permission to access my records and manage health digitally and that I can revoke access at any time.",
  "if-you-authorize-person": "If you authorize this request, then this person can:",
  "access-validity": "This access is valid until you revoke it.",
  "revoke-access-in-settings": "You can revoke access by going to your Medical Info in Account Settings",
  "review-request": "Review request...",
  "authorize": "Authorize",
  "decline": "Decline",
  "notes": "Notes",
  "account-holder": "Account holder",
  "monday-abbreviation": "Mon",
  "tuesday-abbreviation": "Tue",
  "wednesday-abbreviation": "Wed",
  "thursday-abbreviation": "Thu",
  "friday-abbreviation": "Fri",
  "saturday-abbreviation": "Sat",
  "sunday-abbreviation": "Sun",
  "internal-use-only": "Internal use only",
  "to-get-started-choose": "To get you started,\nplease choose one of the following:",
  "face-id": "Face ID",
  "medical-equipment-immunization": "Immunizations, Durable Medical Equipment, Compounting, Patient Consultation, COVID-19 Vaccine",
  "services-offered": "Services offered",
  "en-es-fr": "English, Spanish, French",
  "languages-spoken": "Languages spoken at this location",
  "holiday-hours-vary": "Holiday hours may vary.",
  "open-hours": "Open today: {{hours}}",
  "open-today": "Open today 24 hours.",
  "store-hours": "Store Hours",
  "set-my-store": "Set as my store",
  "manage-prescriptions": "Manage your prescriptions digitally",
  "pick-up-prescriptions": "Pick up your prescriptions",
  "discuss-care": "Discuss your care with a {{pharmacyName}} team member",
  "manage-health": "Manage your health and clinical service appointments",
  "upload-csv": "Upload CSV",
  "add-patient": "Add Patient",
  "revoke-access": "Revoke access",
  "authorized-on": "Authorized on {{authorizedDate}}",
  "request-status": "Pending: submitted on {{submittedDate}}",
  "authorized-caregivers": "authorized caregivers",
  "personal-info": "Personal Info",
  "person": "Person",
  "will-pickup-prescription-today": "Will you be picking your prescription up today?",
  "field-required": "This field is required",
  "will-pickup-prescription-urgently": "Do you need to pickup your prescription urgently?",
  "order-details": "Order Details",
  "method": "Method",
  "api-required": "API key is required",
  "note-for-pharmacy": "Note for the pharmacy (optional)",
  "note-for-pharmacy-not-optional": "Note for the pharmacy",
  "review": "Review",
  "insurance-is-required": "Insurance is required",
  "insurance-change-last-refill": "Has your insurance changed since your last refill? *",
  "prescription-ready-each-month": "Would you like all of your prescriptions to be ready together at the same time each month?",
  "prescription-find-server-error": "Internal Server Error during finding your medications",
  "prescriptions-is-required": "Prescriptions is required",
  "over-the-counter-medications": "Over-the-counter medications",
  "please-review-messages-and-proceed": "Please review the messages below and let us know how you would like to proceed.",
  "how-to-proceed": "How to proceed",
  "enter-patient-email": "Enter patient email address",
  "add-patient-request-submit": "Add patient request submitted",
  "provide-an-email": "Please provide the email address that {{firstName}} {{lastName}} uses for {{firstName}}'s account with {{pharmacyName}}",
  "request-sent": "Request sent to {{firstName}} {{lastName}}",
  "approval-needed": "Approval is needed from this person within 14 days",
  "person-has-an-account": "If this person has an account at {{pharmacyName}}, then they will receive your request",
  "add-another-person": "Add another person",
  "add-another": "Add another",
  "patient-select": "Please select the patient before you begin.",
  "after-adding-patient-message": "After adding a new patient, please return to the original message and click the link again.",
  "another-account": "Another account",
  "go-to-form": "Go to form",
  "new-task": "New task",
  "summary": "Summary",
  "description": "Description",
  "get-mobile-app": "Get our mobile app!",
  "go-home": "Go home",
  "about-this-app": "About this app",
  "copyright-lumistry": "Copyright Lumistry",
  "all-rights-reserved": "All Rights Reserved",
  "version": "Version",
  "enter-patient-information": "Enter patient information",
  "allow-pharmacy-contact-prescriber": "Allow pharmacy to contact prescriber",
  "continue-with-selected-items": "Continue with the selected items",
  "go-back": "Go back",
  "go-back-update-order": "Go back to update my order",
  "thank-you-for-choosing": "Thank you for choosing",
  "return-to-order-details": "Return to order details",
  "appointments": "Appointments",
  "add-medications": "Add medications",
  "add-meds-text": "You can order prescription refills easily and manage medications by adding them now, or skip this step to do it later",
  "prescriptions-at-file": "Prescriptions are on file at Cameron’s Pharmacy",
  "request-to-transfer": "Request to transfer prescriptions from a different pharmacy",
  "skip-this-step": "Skip this step",
  "do-this-medications-tab": "You can do this in the Medications tab",
  "account": "Account",
  "your-account": "Your Account",
  "add-people-under-care": "Add people under your care",
  "add-people-under-care-message-pt-1": "You can find the person you want to add by providing their information on the next page",
  "add-people-under-care-message-pt-2": "If the person you are adding is 18+ years old, then you will also need to provide the email address that they use for their account at",
  "add-person": "Add a person",
  "unable-to-process-items": "Unable to process one or more items",
  "add-override": "Add override",
  "year": "year",
  "month": "month",
  "day": "day",
  "week": "week",
  "we-will-contact-prescriber": "We will contact your prescriber for authorization on the following prescriptions. ",
  "please-review-the-messages-below": "Please review the messages below and let us know how you would like to proceed.",
  "ready-to-be-filled": "Ready to be filled",
  "close": "Close",
  "closed": "Closed",
  "greeting": "Good Morning",
  "your-password-has-been-successfully-changed": "Your password has been successfully changed",
  "other-details": "Other details",
  "store-info": "Store info",
  "account-setup": "Account Setup",
  "other-information": "Other information",
  "insurance": "Insurance",
  "revoke": "Revoke",
  "first-name-is-not-valid": "First name is not valid",
  "login-attempts-exceeded": "Login attempts exceeded",
  "max-attempts-exceeded": "Maximum attempts exceeded",
  "account-locked-reason": "Your account has been locked because you have reached the maximum number of invalid sign-in attempts allowed.",
  "security-reason": "For security purposes, please choose an option below to change your password and recover your account.",
  "send-me-email": "Send me an email",
  "edit-title": "Edit Title",
  "edit-subtitle": "Edit Subtitle",
  "edit-checkbox-label": "Edit Checkbox Label",
  "maximum-input-value": "Maximum input value",
  "send-me-sms": "Send me a text message",
  "last-name-is-not-valid": "Last name is not valid",
  "update-weak-password": "Your password does not meet current requirements. For the security and protection of your account, please create a new one.",
  "revoke-access-for-authorized-caregiver": "Revoking access means that {{firstName}} {{lastName}} is no longer one of your authorized caregivers.",
  "legal-guardian-confirmation-for-person": "You agree that you are a Parent or Legal Guardian for the person you are adding",
  "legal-guardian-agreement": "Yes, I am a Parent or Legal Guardian for {{firstName}} {{lastName}}.",
  "people-under-your-care": "People Under Your Care",
  "add-minor-or-pet-patient": "Add minor or pet patient",
  "your-allergies": "Your allergies",
  "confirmation": "Confirmation",
  "people-under-your-care-empty-page": "If you're managing prescriptions and appointments for other people or pets, having them all in one list is a convenient way to keep track of everyone's medications and schedules which can help avoid missed refills and appointments",
  "add-person-or-pet": "Add person or pet",
  "unable-to-submit-people-under-care-request": "Unable to submit people under care request",
  "relationship-to-me": "Relationship to me",
  "medical-info": "Medical Info",
  "none": "None",
  "notice": "Notice",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "meds": "Meds",
  "orders": "Orders",
  "reminders": "Reminders",
  "no-upcoming-appointments": "No upcoming appointments",
  "no-refills-due": "No refills due",
  "refill-details": "Refill Details",
  "success": "Success",
  "prescription-no": "Prescription No.",
  "name": "Name",
  "status": "Status",
  "submitted": "Submitted",
  "channel": "Channel",
  "delivery-method": "Delivery Method",
  "refill-comments": "Refill Comments",
  "patient-info": "Patient Info",
  "details": "Details",
  "phone": "Phone",
  "address": "Address",
  "email-text": "Email",
  "prescription": "Prescription",
  "patient": "Patient",
  "date-submitted": "Date submitted",
  "allergies-colon": "Allergies: ",
  "medical-conditions": "Medical conditions: ",
  "refill-past-due": "Refill past due:",
  "refill-due": "Refill due:",
  "store": "Store",
  "app": "App",
  "allergies": "Allergies",
  "number": "Number",
  "medical-conditions-without-colon": "Medical Conditions",
  "caregiver-approval-agreement": "Caregiver Approval Agreement",
  "no-known-allergies": "No known allergies",
  "known-allergies": "Known allergies",
  "allergies-required": "Please enter allergies or select \"No known allergies\"",
  "more-information": "More information",
  "monthly-prescriptions": "Would you like your prescriptions to be ready together at the same time each months?",
  "as-an-authorized-caregiver": "As an authorized Caregiver, you can:",
  "manage-prescriptions-digitally": "Manage {{firstName}}'s prescriptions digitally",
  "order-refills": "Order refills for {{firstName}}",
  "create-first-task": "Create your first task",
  "order-refills-prescriptions": "Order refills for your prescriptions",
  "delivery": "Delivery",
  "mail": "Mail",
  "curbside": "Curbside",
  "task-details": "Task Details",
  "are-you-sure-to-delete-task": "Are you sure you want to delete the task",
  "are-you-sure-to-resolve-task": "Are you sure you want to resolve the task",
  "created-by": "Created by",
  "relation-to-primary-user": "Relation to primary user",
  "would-like-easy-open-caps": "Would you like easy-open bottle caps?",
  "prefers-easy-open-caps": "Yes, I prefer easy-open caps",
  "prefers-safety-caps": "No, I prefer safety caps",
  "pick-up-prescriptions-for": "Pick up prescriptions for {{firstName}}",
  "discuss-care-with": "Discuss {{firstName}}'s care with a {{pharmacyName}} team member",
  "manage-health-and-clinic": "Manage {{firstName}}'s health and clinical service appointments",
  "access-validity-puc": "This access is valid until {{firstName}} turns 18 or you remove {{firstName}} from your list of people under your care.",
  "update-puc-list": "You can update your list by going to People Under My Care in Account Settings",
  "patient-record-created": "Patient Record created for {{firstName}} {{lastName}}",
  "medications-empty-state-title": "Medications",
  "phone-number-footer": "phone: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Pharmacy website and mobile app provided by",
  "medications-empty-state-description": "Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "medications-reminders-empty-state-title": "Medication Reminders",
  "medications-reminders-empty-state-description": "Medication reminders is a good way to stay on track of your meds which can help avoid unnecessary risk and serious illness",
  "add-meds": "Add Meds",
  "transfer-meds": "Transfer meds from another pharmacy",
  "pickup": "Pickup",
  "select-an-option": "Please select an option",
  "choose-from-the-list": "Please choose from the list of items before clicking the order button",
  "select-one-item": "Select at least one item",
  "show-more": "Show more",
  "street1": "Street 1",
  "street2": "Street 2",
  "please-select": "Please select",
  "select-single-option": "Please select a single option",
  "select-multiple-options": "Please select multiple options",
  "gender-on-insurance": "Gender (as it appears on your insurance)",
  "preferred-language": "Preferred language",
  "notification-preferences": "Notification Preferences",
  "auto-voice-call": "Auto voice call",
  "edit-patient": "Edit Patient",
  "edit-columns": "Edit Columns",
  "add-new-column": "Add a new column",
  "edit-rows": "Edit Rows",
  "edit-form-title": "Edit form title",
  "add-new-row": "Add new row",
  "show-less": "Show less",
  "please-confirm-the-info-matches-prescription-label": "Please confirm that the information below matches what's on your prescription label",
  "register-confirmation-heading": "New account setup",
  "register-confirmation-body": "A new account setup link has been sent to your email. Please check your email and click the link provided.",
  "schedule": "Schedule",
  "orders-tab-empty-state-title": "Order history",
  "orders-tab-empty-state-description": "Placing a refill order is easy from the Meds tab! After you've placed an order, details and tracking will go here",
  "start-an-order": "Start an order",
  "show-locations": "Show locations",
  "first-name-edit-user": "First name",
  "last-name-edit-user": "Last name",
  "dob-edit-user": "DOB (mm/dd/yyyy)",
  "new-appointment": "New appointment",
  "information": "Information",
  "find-time": "Find a time",
  "learn-more": "Learn more",
  "next-available": "Next available",
  "not-available": "Currently not available",
  "intro": "Intro",
  "enter-email": "Enter email",
  "create-password": "Create password",
  "register-confirmation": "Register confirmation",
  "register": "Register",
  "reset-link": "Reset link",
  "reset-successful": "Reset successful",
  "register-details": "Register details",
  "phone-verification": "Phone verification",
  "patient-pharmacy-stores": "Patient pharmacy stores",
  "add-prescription": "Add prescription",
  "refill-guest": "Refill guest",
  "home": "Home",
  "settings": "Settings",
  "store-location": "Store location",
  "expiring-soon": "Expiring soon",
  "minutes": "Minutes",
  "book-appointment": "Book Appointment",
  "appointments-calendar": "Appointments Calendar",
  "upcoming": "Upcoming",
  "past": "Past",
  "patient-insurance": "Patient insurance",
  "barcode": "Barcode",
  "patient-preferences": "Patient preferences",
  "feedback-success": "Feedback success",
  "people-under-care": "People Under Your Care",
  "add-patient-under-care": "Add patient under care",
  "add-patient-under-care-info": "Add patient under care info",
  "add-patient-under-care-email": "Add patient under care email",
  "add-patient-under-care-confirmation": "Add patient under care confirmation",
  "patient-under-care-list": "Patient under care list",
  "contact-info": "Contact info",
  "automatic-prescription-flow": "Automatic prescription flow",
  "success-automatic-prescription-flow": "Success automatic prescription flow",
  "on-hold": "On hold",
  "rejected-reason-unknown": "Rejected reason unknown",
  "select-person": "Select the patient for this appointment. If you don't see them on the list, you will need to add them first.",
  "myself": "Myself",
  "confirm": "Confirm",
  "error-loading-appointments": "Error loading appointments",
  "error": "Error",
  "see-order-details": "See order details",
  "appointment-scheduled": "appointment is scheduled",
  "booking-error": "Something went wrong when trying to book the appointment. Please try again.",
  "error-loading-slots": "Error loading appointment slots",
  "error-loading-patient-record": "Error loading the patient record",
  "error-updating-patient-record": "Error updating the patient record",
  "error-booking-appointment": "Error booking the appointment",
  "when": "When",
  "who": "Who",
  "where": "Where",
  "care-notes": "Care notes",
  "reschedule": "Reschedule",
  "cancel-appointment": "Cancel appointment",
  "error-loading-appointment": "Error loading appointment",
  "error-loading-appointment-type": "Error loading appointment type",
  "refill-medications": "refill-medications",
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "upcoming-refill": "Upcoming refill",
  "good-morning": "Good morning",
  "send-message": "Send message",
  "good-afternoon": "Good afternoon",
  "good-evening": "Good evening",
  "you-are-chatting-with": "You are chatting with:",
  "new-message": "New Message",
  "no-messages": "No messages",
  "messages-have-a-question": "Have a question? Need to change an order? Send us a secure message.",
  "new-message-placeholder": "Message...",
  "general": "General",
  "billing": "Billing",
  "med-info": "Med info",
  "side-effects": "Side effects",
  "message-subject-label": "Select the subject that best fits your message:",
  "send": "Send",
  "reschedule-appointment": "Reschedule appointment",
  "appointment-rescheduled": "appointment is rescheduled",
  "error-rescheduling-appointment": "Error rescheduling the appointment",
  "cancel-appointment-confirmation": "Are you sure you would like to cancel this appointment?",
  "appointment-canceled": "appointment is canceled",
  "previously-scheduled": "Previously scheduled:",
  "cancel-note": "Note: Canceling this appointment will not remove it from any third-party calendars it has been added to.",
  "cancel-error": "Something went wrong when trying to cancel the appointment. Please try again.",
  "error-cancel-appointment": "Error canceling the appointment",
  "something-went-wrong-implicit": "Something went wrong, please try again later.",
  "keep": "Keep",
  "of": "of",
  "tomorrow": "Tomorrow",
  "today": "Today",
  "canceled": "Canceled",
  "events": "events",
  "error-field-number": "Field must be a number",
  "no-appointments": "You currently have no appointments.\nSelect a service to get started.",
  "no-services-booking": "There are no services available for booking.",
  "download-all-submissions": "Download all submissions",
  "no-submissions-download": "There are no submissions to download",
  "video-header": "Videos",
  "videos": "Videos",
  "videos-filter": "FILTER",
  "videos-clear": "Clear",
  "videos-all": "All",
  "videos-medication": "Medication",
  "videos-condition": "Condition",
  "videos-administration": "Administration",
  "videos-missing-medication-video": "Missing Medication Video",
  "videos-general-health": "General health",
  "videos-search": "Search",
  "videos-share": "Share",
  "videos-category": "Category",
  "videos-title": "Title",
  "videos-language-spanish": "View in Spanish",
  "videos-language-english": "View in English",
  "videos-language-french": "View in French",
  "videos-duration": "Duration",
  "videos-url": "URL",
  "copied-to-clipboard": "Copied to clipboard",
  "videos-med-guides": "Med Guides",
  "videos-transcript": "Transcript",
  "delete-confirmation-modal": "Are you sure you want to delete the {{itemType}}: {{itemName}}?",
  "confirm-to-delete-name": "Are you sure you want to delete the form with the name: {{name}}?",
  "service": "Service",
  "services": "Services",
  "new-service": "New service",
  "confirming-lose-current-form": "By confirming you will lose the current progress on importing a form.",
  "confirm-delete-availability": "Are you sure you want to delete the availability with the name: {{name}}?",
  "same-mailing-address": "Same as mailing address",
  "create-appointment": "Create an appointment",
  "order-refill": "Order refill",
  "limit-booking-frequency": "Limit booking frequency",
  "limit-booking-duration": "Limit total booking duration",
  "limit-future-bookings": "Limit future bookings",
  "before-event": "Before event",
  "after-event": "After event",
  "into-future": "into the future",
  "within-date-range": "Within a date range",
  "add-limit": "Add limit",
  "calendar-days": "calendar days",
  "business-days": "business days",
  "per-day": "Per day",
  "per-week": "Per week",
  "per-month": "Per month",
  "no-buffer-time": "No buffer time",
  "content-box-title": "Displaying tasks that match the following criteria:",
  "duration": "Duration",
  "time": "Time",
  "check-later": "Please check back later",
  "no-availability": "No availability",
  "birthdate": "Birthdate",
  "no-access": "No Access",
  "actions": "Actions",
  "access-private-public": "Access: Private/Public",
  "start-date": "Start date",
  "cannot-delete-task-type": "Can't delete task types that are currently in use",
  "cannot-edit-task-type": "Can't edit default task type",
  "personal-task-assign": "Personal tasks can be assigned only to the owner/creator of the task",
  "start-date-required": "Start date is required",
  "service-record-enabled-tooltip": "Mark service as unavailable for making appointments",
  "service-record-disabled-tooltip": "Enable making appointments for this service",
  "copy-link-button-tooltip": "Copy to clipboard",
  "edit-service-record-tooltip": "Edit service",
  "delete-service-record-tooltip": "Delete service",
  "selecting-all-pages": "Selecting all pages ({{count}} items)",
  "select-all-pages": "Select all pages",
  "selecting-current-page": "Selecting this page only ({{count}} items)",
  "select-current-page": "Select this page only",
  "unselect-all": "Unselect all",
  "others": "others",
  "reorder": "Reorder",
  "generic-bulk-confirmation-modal": "Are you sure you want to {{bulkAction}} {{count}} {{type}} {{changeType}}?",
  "task": "task",
  "bulk-assignee-confirmation-modal": "Select the assignee for {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Select the due date for {{count}} {{type}}.",
  "unresolved": "Unresolved",
  "resolved": "Resolved",
  "in_progress": "In Progress",
  "on_hold": "On hold",
  "task-resolve": "Tasks Resolve",
  "mark-resolved": "Mark as Resolved",
  "mark-in-progress": "Mark as In Progress",
  "mark-flagged": "Mark as Flagged/Unflagged",
  "flagged": "Flagged",
  "mark-unresolved": "Mark as Not Started",
  "mark-on-hold": "Mark as On Hold",
  "change-assignee": "Change Assigned User",
  "user-roles": "User Roles",
  "task-filters": "Task Filters",
  "change-due": `Change Due Date`,
  "task-in-progress": "Tasks In Progress",
  "task-flag": "Tasks Flag",
  "assignee": "Assignee",
  "not-started": "Not Started",
  "over-the-counter": "Over the counter",
  "delete-task": "Delete Task",
  "venue": "Venue",
  "private": "Private",
  "public": "Public",
  "your-pharmacy": "Your pharmacy",
  "edit-availability-record-tooltip": "Edit Availability",
  "delete-availability-record-tooltip": "Delete Availability",
  "availability": "Availability",
  "new-chat-message": "New Chat Message",
  "your-refill-is-ready": "Your refill is ready",
  "hi-your-refill-is-ready": "Hi{{firstName}}, your refill is ready to be picked up.",
  "patient-is-required": "Patient is required!",
  "subject-is-required": "Subject is required!",
  "message-or-attachment-is-required": "Message or attachment is required!",
  "message-characters-limit-five-thousand": "Message characters limit is 5000!",
  "find-template": "Find template",
  "to": "To",
  "subject": "Subject",
  "note-inbox-messages-should-be-clinical-in-nature": "Note: Inbox messages should be clinical in nature. Sending marketing messages through this platform could violate TCPA and FCC regulations. Do not request credit card information.",
  "all": "All",
  "unread": "Unread",
  "unread-by-patient": "Unread by patient",
  "includes-me": "Includes me",
  "created-by-me": "Created by me",
  "last-seven-days": "Last 7 days",
  "filters": "Filters",
  "clear": "Clear",
  "text-formatting": "Text formatting",
  "send-attachment": "Send Attachment",
  "type-your-message": "Type your message...",
  "mark-as-unread": "Mark as unread",
  "chat-messages": "Chat Messages",
  "find-patient": "Find patient",
  "new-subject": "New subject",
  "type-your-question": "Type a question",
  "add-mobile-number-send-message": "Add a mobile number to send a message",
  "add-mobile-number": "Add mobile number",
  "newest": "Newest",
  "oldest": "Oldest",
  "a-z": "A-Z",
  "new-chat": "New chat",
  "inbox": "Inbox",
  "auto": "Auto",
  "previous-week": "Previous week",
  "current-week": "Current week",
  "next-week": "Next week",
  "secure-message": "Secure Message",
  "unable-to-process": "Unable to process your request",
  "try-again-later": "Please try again later. If you need immediate assistance, please contact your pharmacy.",
  "message-pharmacy": "Message pharmacy",
  "call-pharmacy": "Call pharmacy",
  "tasks-grid-no-results-title": "Your search criteria doesn't have any tasks in the current time-frame",
  "tasks-grid-no-results-subtitle": "We couldn't find any tasks in the current time-frame based on your view and filters. Use the Previous / Next buttons to navigate in time, or the Current week button to reset the view to default.",
  "get-directions": "Get directions",
  "explore-other-locations": "Explore other locations offering this service",
  "error-getting-locations": "Error getting locations",
  "zip-code-is-not-valid": "Zip code is not valid",
  "street-1-is-not-valid": "Street 1 is not valid",
  "street-2-is-not-valid": "Street 2 is not valid",
  "allergies-are-not-valid": "Allergies-are-not-valid, a correct example is all1,all2,all3...",
  "conditions-are-not-valid": "Conditions are not valid, a correct example is cond1,cond2,cond3...",
  "make-appointment": "Make an appointment",
  "find-service-at-location": "Find services at a different location",
  "get-more-stores": "Get more stores",
  "no-medication-guide": "Currently unavailable. If you have questions about this medication, contact your pharmacy.",
  "error-loading-patients-under-care": "Error loading patients under care",
  "add-new-patient": "Add new patient...",
  "after-adding-patient": "After adding a new patient, please return to Appointments and select your desired service.",
  "pending-requests": "Pending requests:",
  "add-calendar-event": "Add to calendar",
  "import-forms": "Import forms",
  "import-form": "Import form",
  "number-of-forms": "Number of forms to import",
  "by-application": "By application",
  "by-email": "By email",
  "by-sms": "By SMS",
  "form": "Form",
  "import-plan": "Import Plan",
  "by-voice": "By voice",
  "error-loading-patient": "An error occurred while trying to get the patient. Please try again.",
  "in": "in",
  "ago": "ago",
  "month-short": "m",
  "hour-short": "h",
  "day-short": "d",
  "hours": "Hours",
  "missing-important-patient-record-data": "The patient record is missing import data.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Reset filters",
  "calendar": "Calendar",
  "appointment-panel": "Appointment Panel",
  "appointment-filters": "Appointment Filters",
  "age": "Age",
  "confirmed": "Confirmed",
  "pending": "Pending",
  "rejected": "Rejected",
  "service-description": "Service description",
  "additional-info": "Additional info",
  "more": "More",
  "less": "Less",
  "month-capital": "Month",
  "week-capital": "Week",
  "day-capital": "Day",
  "one-time": "One-time",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "dont-see-service": "Don't see the service you need?",
  "service-category": "Service category",
  "team": 'Team',
  "personal": "Personal",
  "days": "Days",
  "visibility-personal-policy": "It will be viewed by you and the assignee (if assigned)",
  "visibility-team-policy": "It will be viewed by everybody",
  "show-grid": "Show on grid",
  "task-appear-grid": "The task will appear on the Grid and List views.",
  "reschedule-note": "Note: Rescheduling this appointment will not remove it from any third-party calendars it has been added to.",
  "ordered": "Ordered",
  "characters-count": "Characters count: {{count}}",
  "maxim-count-attachments": "Maxim count of attachments is {{count}}.",
  "selecting-files-wrong": "Something went wrong selecting files.",
  "files-sending-error-try-again": "Something went wrong sending files. Please, try again.",
  "home-account-subheading": "Settings, insurance, help, etc",
  "home-medications-subheading": "Manage meds, and order refills",
  "home-messages-subheading": "Securely chat with the pharmacy",
  "home-appointments-subheading": "View or schedule clinical services",
  "home-puc-subheading": "Add a person...",
  "create-form-first": "Please create a form first",
  "pharmacy": "Town Pharmacy",
  "pharmacy-general-info": "General info",
  "pharmacy-edit-general-info": "Edit General info",
  "pharmacy-general-details": "Details",
  "pharmacy-general-info-limited-brand-name": "Limited to 16 characters",
  "pharmacy-company-name": "Pharmacy short name",
  "pharmacy-brand-full-name": "Pharmacy full name",
  "pharmacy-brand-admin": "Brand admin",
  "pharmacy-parent-company": "Parent company",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "Twitter",
  "pharmacy-contact-info": "Contact info",
  "pharmacy-edit-contact-info": "Edit Contact info",
  "pharmacy-email-address": "Email address",
  "pharmacy-phone-number": "Phone number",
  "pharmacy-fax-number": "Fax number",
  "pharmacy-mailing-address": "Mailing address",
  "pharmacy-billing-address": "Billing address",
  "pharmacy-brand-name-length": "The name must not exceed 16 characters",
  "pharmacy-departments": "Departments",
  "pharmacy-names-details": "Names",
  "display-name": "Display name",
  "length": "Length",
  "pharmacy-edit-department": "Edit Department",
  "pharmacy-delete-department": "Do you want to delete the Department?",
  "pharmacy-delete-department-info": "By confirming you will delete the selected department.",
  "delete-task-type-confirm": "By confirming you will delete the selected task type.",
  "pharmacy-departments-name": "Department",
  "pharmacy-departments-hours": "Department hours",
  "pharmacy-departments-hours-subtitle": "The default hours for all stores. Individual stores can override these settings.",
  "pharmacy-departments-name-required": "Department Name is Required.",
  "pharmacy-departments-add-hours": "Add hours",
  "pharmacy-departments-remove-hours": "Remove",
  "all-statuses": "All statuses",
  "pharmacy-holiday-closures": "Holiday closures",
  "pharmacy-holiday-closures-description": "Annually recurring store closures.",
  "pharmacy-additional-closures": "Additional closures",
  "pharmacy-additional-closures-description": "These are not recurring.",
  "pharmacy-additional-closures-active": "Active",
  "pharmacy-additional-closures-inactive": "Inactive",
  "pharmacy-additional-name-required": "Closure name is required",
  "pharmacy-additional-date-required": "Closure date is required",
  "pharmacy-additional-hour-required": "Closure hours interval is required",
  "pharmacy-additional-label": "Label",
  "new": "New",
  "new-availability": "New availability",
  "url-is-not-valid": "URL is not valid",
  "ok": "OK",
  "upload": "Upload",
  "replace": "Replace",
  "front-photo-missing": "Front image is missing!",
  "back-photo-missing": "Back image is missing!",
  "no-allergies": "No allergies",
  "easy-open-bottle-caps-confirm": "Would you like easy-open bottle caps?",
  "allergy-info":"Confirm that allergy info is up-to-date",
  "forms-fill-out":"Fill out {{formsCount}} required forms...",
  "every-n-weeks": "Every {{number}} weeks",
  "every-n-months": "Every {{number}} months",
  "required-field": "Required field",
  "no-puc-access": "You don't currently have permission to access this patient's record. To gain access, please go to your Account settings and add them as a Patient Under Care.",
  "forms-filled-in": "Fill out required forms",
  "health-info":"Review related health info",
  "insurance-card-reminder":"Bring insurance card",
  "changed-insurance": "Has your insurance changed since your last refill?",
  "pre-appointment-checklist":"Pre-appointment checklist",
  "error-updating-appointment": "Error updating the appointment",
  "message-body-is-required": "Message body is required",
  "verify-patient": "Verify patient",
  "verify-patient-warning": "Please verify this patient's name, date of birth, and phone number before disclosing any personal health information",
  "no-forms-required": "No forms were required for this appointment",
  "form-unavailable": "Form is no longer available",
  "form-not-started": "Form not started",
  "form-submitted": "Form has been submitted",
  "no-form-templates": "No form templates available.",
  "lose-data-creating-form": "By confirming you will lose the current progress on creating a new form from a template.",
  "download": "Download",
  "preparing-submission": "Preparing the submission",
  "download-will-begin": "Your download will begin shortly.",
  "delete-submission-confirmation": "Are you sure you want to delete this submission?",
  "not-entered": "Not entered",
  "submitted-on": "Submitted On",
  "download-pdf": "Download PDF",
  "submission-modal": "Submission Modal",
  "medical-information": "Medical information",
  "yes-easy-caps": "Yes, I prefer easy-open caps",
  "no-safety-caps": "No, I prefer safety caps",
  "easy-open-bottle-caps-description": "Federal regulations require that most medications be packaged with child-resistant “safety” caps. Your choice of easy-open caps indicates your desire to have medications dispensed with non-safety caps.",
  "checklist-complete": "Checklist completed",
  "checklist-incomplete": "Please complete the checklist",
  "service-status-updated": "Service status updated successfully",
  "error-loading-form": "Error loading form",
  "account-not-found-heading": "Account not found",
  "every-week": "Every week",
  "every": "Every",
  "weeks": "weeks",
  "account-not-found-subheading": "If you created your account using your Apple or Google Sign-On, please sign in with that account",
  "account-not-found-subheading-sso": "If you created your account using your email address, please sign in with your email",
  "invalid-email-format": "Invalid email format",
  "close-confirmation-question": "Are you sure you want to exit?",
  "close-confirmation-text": "This form is required to schedule your appointment.",
  "close-confirmation-exit": "Exit",
  "close-confirmation-keep-appointment": "Keep appointment",
  "password-reset-subheading": "For the security and protection of your account, please create a new password.",
  "save-password": "Save password",
  "venue-in-person": "In Person",
  "confirm-to-make": "Are you sure you want to make this form",
  "venue-virtual": "Video",
  "venue-over-the-phone": "Phone",
  "get-the-app": "Get the app",
  "all-tasks": "All tasks",
  "rx-order-progress": "Order #{{orderId}} In Progress",
  "prescriptions-filled": "{{filled}} of {{total}} prescriptions are filled",
  "manage-subscription": "Manage your and your family's prescriptions, communicate with your pharmacy, access educational materials, schedule appointments, and more!",
  "edit-reschedule-this-appointment": "Edit or reschedule this appointment",
  "cancel-this-appointment": "Cancel this appointment",
  "message-this-patient": "Message this patient",
  "contact-pharmacy-for-policy-info": "Please contact your local pharmacy for more information about their",
  "prescriptions-empty-state-paragraph-one": "Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "prescriptions-empty-state-paragraph-two": "To add your prescriptions now, tap the button below. If you would like to transfer your medications from another pharmacy, please tap the link above.",
  "prescriptions-empty-state-button-text": "Add meds",
  "otc-empty-state-paragraph-one": "Letting your pharmacy know about all the medications, vitamins and supplements you take can help them look out for possible drug interactions, keeping you safer and healthier!",
  "otc-empty-state-paragraph-two": "This includes any medications you use that don’t require a prescription",
  "otc-empty-state-button-text": "Add OTC meds",
  "viewing-medications-for": "Viewing medications for:",
  "mark-unread": "Mark unread",
  "error-occurred-title": "An unexpected error has occurred",
  "error-occurred-description": "If you need immediate assistance, please contact your pharmacy.",
  "contact-prescription-ready": "We will contact you when your prescription is ready",
  "task-type": "Type",
  "task-status": "Status",
  "task-assigned-to": "Assigned to",
  "task-priority": "Priority",
  "task-created-by": "Created by",
  "created": "Created",
  "create-refill": "Create Refill",
  "updated-without-date": "Updated",
  "completed": "Completed",
  "task-due-by": "Due by",
  "integration": "Integration",
  "due-date": "Due date",
  "api-host": "API Host",
  "server-address": "Server Address",
  "server-port": "Server Port",
  "api-key": "API Key",
  "setup-integration": "Setup Integration",
  "integration-missing": "Currently no integration setup",
  "edit-integration": "Edit Integration",
  "edit-date-override": "Edit date override",
  "date-overrides": "Date overrides",
  "pharmacy-software": "Pharmacy Software",
  "npi": "NPI",
  "task-visibility": "Visibility",
  "prescription-info":"Prescription info",
  "add-to-list": " Add to list",
  "adding-prescriptions-for": "Adding prescriptions for ",
  "last-submission": "Last submission",
  "no-submissions": "No forms has been submitted",
  "search-forms": "Search forms",
  "no-submissions-found": "No results found for ",
  "delete-availability-in-use-tooltip": "This availability is in use for services. Please\nremove/change the service availability and\nthen come back to remove the availability.",
  "add-patients-start-messaging": "Add your patients to start messaging",
  "upload-patients-csv": "Upload patients via CSV",
  "photo-id-2": "Photo ID",
  "find-meds": "Find Meds",
  "rx-number-must-be-numeric": "Please enter only numbers",
  "no-notes-attached": "No notes are attached to this appointment",
  "error-occurred-integration-save": "Error occurred while trying to save integration.",
  "error-occurred-integration-load-save": "An error occurred while trying to load or save integration. Please try again.",
  "integration-saved": "Integration saved",
  "hide": "Hide",
  "enter-valid-phone-number": "Please enter a valid phone number.",
  "end-date": "End date",
  "server-address-required": "Server Address is required",
  "start-time-required": "Start time is required",
  "end-time-required": "End time is required",
  "custom-filters": "Custom Filters",
  "custom-domain": "Custom Domain (Optional)",
  "appointments-filters-info-title": "Displaying appointments that match following criteria:",
  "service-name-info-box": "Service Name set to ",
  "service-category-info-box": "Service Category set to ",
  "applied-to-all-locations": "Applied to all locations",
  "apply-to-all": "Apply to all",
  "set-to-default": "Set to default",
  "availability-name": "Availability name",
  "name-is-required": "Name is required",
  "appointment-notes-update-error": "An error occurred while trying to update the appointment notes. Please try again.",
  "add-image": "Add image",
  "add-file": "Add file",
  "pharmacy-npi-number": "Pharmacy NPI Number",
  "have-second-insurance": "Do you have a second insurance? ",
  "my-tasks": "My tasks",
  "access-denied": "Access denied",
  "check-back-later": "Please check back later or contact the pharmacy if you have questions.",
  "contact-administration": "Contact the IT administrator if you have questions",
  "new-messages": "New messages",
  "this-week": "This week",
  "this-month": "This month",
  "older": "Older",
  "time-sensitive": "Time sensitive",
  "profile-settings": "Profile settings",
  "switch-user": "Switch users",
  "audit-trail": "Audit trail",
  "automated-actions": "Automated actions",
  "help-support": "Help and support",
  "internal-demos": "Internal Demos",
  "vow-ivr": "Vow IVR",
  "wellness-classes": "Wellness classes",
  "event-logs": "Event logs",
  "subscribe-to-webhooks": "Subscribe to Realtime Updates (Webhooks)",
  "realtime-updates": "Realtime Updates",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "overwrite-defaults": "Overwrite Defaults",
  "notifications": "Notifications",
  "no-notifications": "No Notifications",
  "no-notifications-subtitle": "We'll notify you when something happens",
  "add-manually": "Add manually",
  "authentication-failed-generic": "Authentication failed",
  "realtime-updates-api-key": "Realtime Updates API Key",
  "internal-webhook-keys-renewed": "Internal Webhook API Key Renewed",
  "error-occurred-internal-webhook-keys-renew": "Error Occurred while renewing Internal Webhook API Key",
  "refresh": "Refresh",
  "relationship-to-me-is-required": "Relationship to me is required",
  "at": "at",
  "workflow-status-in-queue": "In queue",
  "workflow-status-out-of-stock": "Out of stock",
  "workflow-status-special-order": "Special order",
  "workflow-status-in-process": "In process",
  "workflow-status-awaiting-insurance-authorization": "Awaiting insurance",
  "workflow-status-awaiting-prescriber-authorization": "Awaiting prescriber",
  "workflow-status-ready-for-shipping": "Ready for shipping",
  "workflow-status-ready-for-delivery": "Ready for delivery",
  "workflow-status-partial-fill": "Partial fill",
  "workflow-status-partially-filled": "Partially filled",
  "workflow-status-delivery-in-progress": "Out for delivery",
  "workflow-status-ready-for-pickup": "Ready for pickup",
  "workflow-status-dispensed": "Dispensed",
  "workflow-status-mail": "Mail",
  "workflow-status-shipped": "Shipped",
  "workflow-status-delivered": "Delivered",
  "workflow-status-returned": "Returned",
  "workflow-status-returned-to-stock": "Returned to stock",
  "workflow-status-cancelled": "Canceled",
  "workflow-status-expired": "Expired",
  "workflow-status-discontinued": "Discontinued",
  "workflow-status-transferred": "Transferred",
  "workflow-status-insurance-refused": "Refused by insurance",
  "workflow-status-prescriber-refused": "Refused by prescriber",
  "workflow-status-undefined": "-",
  // Workflow Statuses Long
  "workflow-status-in-queue-message": "This Rx is in the refill queue",
  "workflow-status-out-of-stock-message": "This Rx is out of stock",
  "workflow-status-special-order-message": "This Rx is on special order",
  "workflow-status-in-process-message": "This Rx is in the process of refilling",
  "workflow-status-awaiting-insurance-authorization-message": "This Rx is awaiting insurance authorization",
  "workflow-status-awaiting-prescriber-authorization-message": "This Rx is awaiting prescriber authorization",
  "workflow-status-ready-for-shipping-message": "This Rx is filled and ready for shipping",
  "workflow-status-ready-for-delivery-message": "This Rx is filled and ready for delivery",
  "workflow-status-partial-fill-message": "This Rx was partially filled",
  "workflow-status-partially-filled-message": "This Rx was partially filled",
  "workflow-status-delivery-in-progress-message": "This Rx has been sent out for delivery",
  "workflow-status-ready-for-pickup-message": "This Rx is filled and ready for pickup",
  "workflow-status-dispensed-message": "This prescription has been dispensed",
  "workflow-status-mail-message": "This prescription has been mailed",
  "workflow-status-shipped-message": "This prescription has been shipped",
  "workflow-status-delivered-message": "This prescription has been delivered",
  "workflow-status-returned-message": "This prescription was returned",
  "workflow-status-returned-to-stock-message": "This prescription was returned to stock",
  "workflow-status-cancelled-message": "This prescription was canceled",
  "workflow-status-expired-message": "This prescription has expired",
  "workflow-status-discontinued-message": "This prescription has been discontinued",
  "workflow-status-transferred-message": "This prescription was transferred",
  "workflow-status-insurance-refused-message": "This prescription was refused by the insurance provider",
  "workflow-status-prescriber-refused-message": "This prescription was refused by the prescriber",
  "workflow-status-undefined-message": "Info unavailable",
  // Rx Statuses
  "rx-status-no-refills": "No more refills",
  "rx-status-expired": "Rx expired",
  "rx-status-too-soon": "Rx too soon",
  "rx-status-not-found": "Rx not found",
  "rx-status-controlled": "Controlled",
  "rx-status-narcotic": "Narcotic",
  "rx-status-transferred": "Transferred",
  "rx-status-discontinued": "Discontinued",
  "rx-status-on-hold": "On hold",
  "rx-status-deleted": "Rx deleted",
  "rx-status-fillable": "Refill OK",
  "rx-status-partially-filled": "Partial fill",
  "rx-status-out-of-stock": "Out of stock",
  "rx-status-special-order": "Special order",
  "rx-status-deceased": "Patient deceased",
  "rx-status-undefined": "-",
  // Rx Statuses Long
  "rx-status-no-refills-message": "This Rx has no more refills available",
  "rx-status-expired-message": "This prescription has expired",
  "rx-status-too-soon-message": "It is too soon to refill this prescription",
  "rx-status-not-found-message": "There is no record of this prescription number",
  "rx-status-controlled-message": "This prescription is a controlled substance",
  "rx-status-narcotic-message": "This prescription is a narcotic",
  "rx-status-transferred-message": "This prescription was transferred",
  "rx-status-discontinued-message": "This prescription has been discontinued",
  "rx-status-on-hold-message": "This prescription is on hold",
  "rx-status-deleted-message": "This prescription was deleted",
  "rx-status-fillable-message": "This prescription is good for refilling",
  "rx-status-partially-filled-message": "This prescription was partially filled",
  "rx-status-out-of-stock-message": "This prescription is out of stock",
  "rx-status-special-order-message": "This prescription is on special order",
  "rx-status-deceased-message": "The prescription belongs to a deceased patient",
  "rx-status-undefined-message": "Info unavailable",
  // Order Statuses
  "order-status-received": "Received",
  "order-status-filled": "Filled",
  "order-status-out-for-delivery": "Out for delivery",
  "order-status-ready-for-pickup": "Ready for pickup",
  "order-status-picked-up": "Picked up",
  "order-status-mailed": "Mailed",
  "order-status-shipped": "Shipped",
  "order-status-delivered": "Delivered",
  "order-status-return-to-stock": "Returned to stock",
  "order-status-info-unavailable": "Info unavailable, contact your pharmacy",
  "order-status-undefined": "Info not available, try again later",
  // Order Progress Statuses
  "order-progress-not-started": "Not started",
  "order-progress-started": "Started",
  "order-progress-completed": "Completed",
  "order-progress-undefined": "(n/a)",
  //
  "preview": "Preview",
  "form-not-editable": "This form is not editable",
  "form-preview": "Form Preview",
  "dob-age": "DOB (age)",
  "form-name": "Form name",
  "continuation-disclaimer-register": "By tapping Next, you agree to this Pharmacy's",
  "no-edit": "No edit",
  "imported": "Imported",
  "inactive": "Inactive",
  "submissions": "Submissions",
  "last-modified": "Last modified",
  "copy-url": "Copy URL",
  "active": "Active",
};

export default mapping;
